import * as React from "react";
import { useWindowDimensions } from "../hooks/dimensions";

const Footer = ({ absolute }: { absolute?: boolean }) => {
  const dimensions = useWindowDimensions();

  return (
    <>
      <footer
        className={
          (absolute ? "absolute bottom-5 left-0 right-0" : "mb-5") +
          " text-center text-sm z-10"
        }
        style={{
          color:
            dimensions.width / dimensions.height > 1.5
              ? dimensions.width / dimensions.height > 2
                ? "rgba(255, 255, 255, 0)"
                : "hsla(31,28%, 75%, 0.9)"
              : "hsla(31,28%, 75%, 0.5)",
        }}
      >
        <div className="grid gap-2 px-4">
          <div className="">
            We believe that AI is here to make us all superhuman, in a future
            that is beautiful, delightful, and productive.
          </div>
          <div>
            Contact us at{" "}
            <a href="mailto:magic@anycommand.ai">magic@anycommand.ai</a>.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
