import { useCallback, useEffect, useState, useRef } from "react";
import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import { StaticImage } from "gatsby-plugin-image";
import {
  useMotionValue,
  motion,
  AnimatePresence,
  useSpring,
} from "framer-motion";
import { useWindowDimensions } from "../hooks/dimensions";
import spaceimg from "../images/space.jpg";

const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const dimensions = useWindowDimensions();
  const [frameDimensions, setFrameDimensions] = useState({
    width: 0,
    height: 0,
  });
  const frame = useRef<HTMLDivElement>(null);

  const [sub, setSub] = useState<"loading" | "success" | "init" | "error">(
    "init"
  );

  const [smallScreen, setSmallScreen] = useState(false);

  const scale = useMotionValue(1);
  const scaleSpring = useSpring(scale);

  useEffect(() => {
    if (dimensions.width === 0) {
      return;
    }
    if (dimensions.width * dimensions.height < 640000) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [dimensions]);

  useEffect(() => {
    if (frame.current === null) {
      return;
    }
    console.log("frame.current", frame.current);
    setFrameDimensions({
      width: frame.current.offsetWidth,
      height: frame.current.offsetHeight,
    });
    const observer = new ResizeObserver((entries) => {
      setFrameDimensions({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      });
    });
    observer.observe(frame.current);
    return () => {
      observer.disconnect();
    };
  }, [frame.current, open]);

  const submit = useCallback(async () => {
    setSub("loading");
    const extra = {};
    const data = {
      email,
      extra: JSON.stringify(extra),
      landing_id: "anycommand-v1",
    };
    const dataBytes = JSON.stringify(data);
    try {
      const resp = await fetch(process.env.GATSBY_API_URL + "/waitlist", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        // redirect: "follow", // manual, *follow, error
        // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: dataBytes, // body data type must match "Content-Type" header
      });
      const respD = await resp.json();
      console.log(respD);
      setSub("success");
    } catch (e) {
      setSub("error");
    }
  }, [email]);

  const openComp = (
    <>
      <h1 className="font-['Lora'] text-4xl sm:text-5xl mb-2">Anycommand</h1>
      <p>
        Large language models make computers feel magical. Anycommand lets you
        build that magic into your product, in hours.
      </p>
      <p className="">
        We are building a text-to-action API, mapping user intent to actions in
        your code. With this, your users can accomplish exactly what they want,
        faster, easier, and, we think, more delightfully than before. For
        example:
      </p>
      {/* <div className="relative h-20">
              <div className="absolute left-0">
                <div>hunt for the equalizer in a hidden settings menu</div>
                <div>
                </div>
                <div>
                  watch frustrating youtube videos to figure out which buttons
                  to click
                </div>
              </div>
              <div className="absolute right-0 top-0">
                <div>type "increase the bass for this song"</div>
                <div>type "bump all emails I sent two days ago"</div>
                <div>type "make the corners rounded"</div>
              </div>
            </div> */}
      <div className="grid gap-6">
        <div className="grid justify-items-center ">
          <div className="text-center">
            &ldquo;increase the bass for this song&rdquo;
          </div>
          <div className="text-sm italic">
            instead of hunting for the equalizer in Spotify's hidden settings
            menu
          </div>
        </div>
        <div className="grid justify-items-center ">
          <div className="text-center">
            &ldquo;bump all emails I sent two days ago&rdquo;
          </div>
          <div className="text-sm italic">
            instead of wading through your Gmail inbox and writing the same
            thing ten times
          </div>
        </div>
        <div className="grid justify-items-center ">
          <div className="text-center">
            &ldquo;slow down the last 10 seconds of this video&rdquo;
          </div>
          <div className="text-sm italic">
            instead of watching the ad-ridden "How to Slow Down a Clip in
            Premiere Pro" on YouTube
          </div>
        </div>
      </div>
      <p className="mt-2">
        Anycommand is in early alpha, and we're looking for product-obsessed
        developers to try it out. Add your email below, and we'll be in touch.
      </p>
      <div className="text-center grid place-content-center">
        <div className="text-center flex gap-4 flex-col sm:flex-row items-center">
          <input
            type="email"
            placeholder="you@company.com"
            className="min-w-[10rem] sm:min-w-[20rem] text-center border border-white py-2 px-4 rounded-full outline-none bg-[rgba(255,255,255,0.1)] placeholder-[rgba(255,255,255,0.5)] font-sans"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submit();
              }
            }}
          />
          <button
            className={
              "py-2 w-24 rounded-full backdrop-blur-sm bg-[rgba(255,255,255,0.1)] border-white border transform hover font-sans transition-all" +
              " " +
              // (sub === "loading" ? "animate-pulse" : "") +
              " " +
              // (sub === "success" ? "px-8" : "px-4") +
              ""
            }
            onClick={submit}
          >
            {sub == "init" && "Submit"}
            {sub == "loading" && "Submit"}
            {sub == "success" && "   ✓   "}
          </button>
        </div>
      </div>
    </>
  );

  // const closedComp = (
  //   <div
  //     className="max-w-3xl mx-auto min-h-screen relative text-lg font-['Lora']"
  //     onClick={() => setOpen(true)}
  //   >
  //     <div className="absolute top-1/2 -translate-y-1/2 grid gap-6 text-white px-16 py-10 rounded-[3rem] shadow-lg backdrop-blur-lg bg-[rgba(40,0,0,0.3)] w-full justify-items-center cursor-pointer hover:py-32 transition-all duration-[5000ms]">
  //       <h1 className="font-['Lora'] text-5xl mb-2">Anycommand</h1>
  //     </div>
  //   </div>
  // );
  const closedComp = (
    <>
      <h1 className="font-['Lora'] text-4xl mb-1 text-center">Anycommand</h1>
    </>
  );

  console.log("dimensions", dimensions);
  console.log("dimensionsproduct", dimensions.width * dimensions.height);
  console.log("frameDimensions", frameDimensions);

  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        backgroundColor: "#000000",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anycommand: Natural language UI for everyone.</title>
        <meta name="description" content="Natural language for everyone." />
        <meta name="theme-color" content="#000000" />
      </Helmet>

      <div
        style={{
          backgroundColor: "#000000",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {smallScreen && (
          <StaticImage
            src="../images/space.jpg"
            alt="Photo of Sualeh looking into the camera, smiling."
            loading="eager"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
            }}
            placeholder="blurred"
          ></StaticImage>
        )}
        {!smallScreen && (
          <motion.img
            src={spaceimg}
            alt="Photo of Sualeh looking into the camera, smiling."
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
              scale: scaleSpring,
            }}
            placeholder="blurred"
          ></motion.img>
        )}
        <div className="max-w-3xl mx-auto min-h-screen relative text-lg font-['Lora']">
          <AnimatePresence>
            {(open || smallScreen) && (
              <motion.div
                className={
                  "grid gap-6 text-white px-4 sm:px-16 py-10 rounded-[3rem] shadow-lg backdrop-blur-lg bg-[rgba(40,0,0,0.3)] w-full " +
                  (!smallScreen ? "absolute top-1/2" : "mt-4 mb-4")
                }
                ref={frame}
                animate={{
                  translateY: !smallScreen ? "-50%" : "0%",
                  scale: 1,
                  transition: {
                    delay: 0.2,
                    duration: 0.3,
                    type: "spring",
                    bounce: 0.3,
                  },
                }}
                initial={{
                  translateY: !smallScreen ? "-50%" : "0%",
                  scale: !smallScreen ? 0 : 1,
                }}
                exit={{
                  translateY: "-50%",
                  scale: 0.1,
                }}
              >
                {openComp}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!(open || smallScreen) && (
              <motion.div
                className={
                  "absolute top-1/2 left-1/2 grid gap-6 text-white px-14 py-8 rounded-[3rem] shadow-lg backdrop-blur-lg bg-[rgba(40,0,0,0.3)] cursor-pointer"
                }
                whileHover={{
                  scale: 5,
                  transition: { duration: 8 },
                }}
                onMouseEnter={() => {
                  // every 1 ms, increase scale by 0.0001
                  const id = setInterval(() => {
                    console.log("hi");
                    scale.set(scale.get() * 1.0005);
                  }, 1);
                  // on mouseLeave, cancel the interval
                  console.log(window.timer);
                  window.timer = id;
                  console.log(window.timer);
                }}
                onMouseLeave={() => {
                  scale.set(1);
                  console.log(window.timer);
                  if (window.timer) {
                    console.log();
                    clearInterval(window.timer);
                  }
                }}
                onClick={() => {
                  setOpen(true);
                  scale.set(1);
                  if (window.timer) {
                    clearInterval(window.timer);
                  }
                }}
                initial={{
                  scale: 0.1,
                  translateY: "-50%",
                  translateX: "-50%",
                }}
                animate={{
                  scale: 1,
                  translateY: "-50%",
                  translateX: "-50%",
                }}
                exit={{
                  translateY: "-50%",
                  translateX: "-50%",
                  scale: 0,
                  transition: {
                    duration: 0.2,
                    type: "anticipate",
                  },
                }}
              >
                {closedComp}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {!smallScreen ? <Footer absolute={true} /> : null}
      </div>
    </main>
  );
};

export default IndexPage;
